import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import dynamic from "next/dynamic"
import { parameterize } from "@lib/utils"

// ---------------------------------------------------------

import Image from "@components/image"
import Button from "@components/button"

const LogoTrain = dynamic(() => import("@components/logo-train"), { ssr: false })

// ---------------------------------------------------------

import {
  flip_text_cube,
  buttons_container,
  flip_text,
  hero_container,
  hero_wrapper,
  hero_content,
  hero_header,
  hero_subheader,
  hero_image,
  logo_train_container
} from "./styles.module.scss"

// ---------------------------------------------------------

const HomepageHero = ({ primaryButton, secondaryButton, image, subheader, logoTrain }) => {
  const [rotation, setRotation] = useState(0)
  const [isRotating, setIsRotating] = useState(true)

  const words = ["clarity", "value", "engagement", "loyalty"]

  // -------------------------------------------------------
  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsRotating(!document.hidden)
    }

    document.addEventListener("visibilitychange", handleVisibilityChange)
    return () => document.removeEventListener("visibilitychange", handleVisibilityChange)
  }, [])

  //TODO determine a better way to animate "flip down" infinitely without creating a very large number.
  useEffect(() => {
    if (!isRotating) return

    const interval = setInterval(() => {
      setRotation((prevRotation) => prevRotation - 90) // Rotate by 90 degrees every interval
    }, 5000)

    return () => clearInterval(interval)
  }, [isRotating])

  // -------------------------------------------------------

  return (
    <section className={hero_container} data-cy="homepage">
      <div className={hero_wrapper}>
        <div className={hero_content}>
          <h1 className={hero_header}>
            Turn customer data into customer{" "}
            <div className={flip_text_cube} style={{ transform: `rotateX(${rotation}deg)` }}>
              {words.map((word, i) => (
                <div className={flip_text} key={i}>
                  {word}
                </div>
              ))}
            </div>
          </h1>
          <p className={hero_subheader}>{subheader}</p>
          <div className={buttons_container}>
            {primaryButton && (
              <Button
                theme={parameterize(primaryButton.theme) || "arrow-light"}
                labelAttr={primaryButton.labelAttr}
                url={primaryButton.url}
              >
                {primaryButton.labelAttr}
              </Button>
            )}
            {secondaryButton && (
              <Button
                theme={parameterize(secondaryButton.theme) || "arrow-light"}
                labelAttr={secondaryButton.labelAttr}
                url={secondaryButton.url}
              >
                {secondaryButton.labelAttr}
              </Button>
            )}
          </div>
        </div>
        <Image
          className={hero_image}
          {...image}
          alt={image.alt}
          src={image.src}
          sizes="(min-width: 1440px) 50vw, (min-width: 960px) 480px, 100vw"
          style={{ objectFit: "fill" }}
        />
      </div>
      <div className={logo_train_container}>
        <LogoTrain
          backgroundColor="transparent"
          animated={logoTrain.animated}
          logos={logoTrain.logos}
        />
      </div>
    </section>
  )
}

HomepageHero.propTypes = {
  /**
   * Image of homepage hero.
   */
  image: PropTypes.shape({
    alt: PropTypes.string,
    src: PropTypes.string.isRequired,
    height: PropTypes.number,
    width: PropTypes.number
  }),

  /**
   * Button that serves as main call to action in hero.
   */
  primaryButton: PropTypes.shape({
    url: PropTypes.string,
    title: PropTypes.string,
    labelAttr: PropTypes.string,
    theme: PropTypes.string
  }),

  /**
   * Button that serves as secondary call to action in hero.
   */
  secondaryButton: PropTypes.shape({
    url: PropTypes.string,
    title: PropTypes.string,
    labelAttr: PropTypes.string,
    theme: PropTypes.string
  }),

  /**
   * Logo train at the bottom of the hero.
   */
  logoTrain: PropTypes.object,

  /**
   * Subheading of hero element.
   */
  subheader: PropTypes.string
}

export default HomepageHero
