import PropTypes from "prop-types"
import classNames from "classnames"

// ---------------------------------------------------------

import { hasDarkBackground } from "@lib/utils"

import Image from "@components/image"

// ---------------------------------------------------------

import {
  badge_image,
  dark_theme,
  rating_image,
  rating_small,
  rating_default,
  rating_default_content,
  rating_small_content
} from "./styles.module.scss"

const themeOptions = {
  default: rating_default,
  small: rating_small
}

// ---------------------------------------------------------

const Rating = (props) => {
  let {
    backgroundColor = "off-black",
    className,
    header,
    description,
    ratingImage,
    badge,
    theme = "default"
  } = props

  // ---------------------------------------------------------

  const darkBackground = hasDarkBackground(backgroundColor)

  const classes = classNames(themeOptions[theme], {
    [className]: className,
    [`background_${backgroundColor}`]: backgroundColor,
    [dark_theme]: darkBackground
  })

  if (theme === "small") {
    return (
      <div className={classes}>
        <div className={rating_small_content}>
          <h3>{header}</h3>
          <Image
            className={rating_image}
            src={ratingImage.url}
            alt={ratingImage.description}
            width={ratingImage.width}
            height={ratingImage.height}
          />
        </div>
      </div>
    )
  } else {
    return (
      <div className={classes}>
        <div className={rating_default_content}>
          {badge?.url && (
            <Image
              className={badge_image}
              src={badge.url}
              alt={badge.description}
              width={badge.width}
              height={badge.height}
            />
          )}
          <div>
            <Image
              className={rating_image}
              src={ratingImage.url}
              alt={ratingImage.description}
              width={ratingImage.width}
              height={badge.height}
            />
            {header && <h2>{header}</h2>}
            <h5>{description}</h5>
          </div>
        </div>
      </div>
    )
  }
}

Rating.propTypes = {
  /**
   * Specifies the rating background color.
   */
  backgroundColor: PropTypes.oneOf([
    "anvil",
    "cement",
    "ice",
    "lagoon",
    "limoncello",
    "off-black",
    "white"
  ]),

  /**
   * Specifies additional class names.
   */
  className: PropTypes.string,

  /**
   * The title of the rating component.
   */
  header: PropTypes.string.isRequired,

  /**
   * Additional text supporting header.
   */
  description: PropTypes.string,

  /**
   * An image containing the star rating.
   */
  ratingImage: PropTypes.shape({
    description: PropTypes.string,
    height: PropTypes.number,
    url: PropTypes.string.isRequired,
    width: PropTypes.number
  }).isRequired,

  /**
   * Badge that the rating company provides.
   */
  badge: PropTypes.shape({
    description: PropTypes.string,
    height: PropTypes.number,
    url: PropTypes.string.isRequired,
    width: PropTypes.number
  }),

  /**
   * Specifies the rating theme (size).
   */
  theme: PropTypes.oneOf(["small", "default"])
}

export default Rating
