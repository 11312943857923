/* eslint react/prop-types: "off" */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react"
import { useRouter } from "next/router"
import PropTypes from "prop-types"
import Button from "../../button"
import DropdownSelector from "../../dropdown-selector"
import { ReactSVG } from "react-svg"
import Link from "next/link"

import {
  jobs_container,
  jobs_list,
  job_post,
  job_content,
  job_content_column,
  current_openings,
  filters_container,
  results_container,
  on_hover_apply,
  filters_header,
  button_clear_filters,
  button_view_all,
  close_icon,
  total_jobs,
  select_input,
  notice
} from "./styles.module.scss"

const INITIAL_VIEWCOUNT = 15

const Careers = (props) => {
  const router = useRouter()
  const [filteredJobs, setFilteredJobs] = useState(props.jobs || [])
  const [jobCount, setJobCount] = useState(props.jobs?.length || 0)
  const [viewAll, setViewAll] = useState(false)
  const [department, setDepartment] = useState("All departments")
  const [location, setLocation] = useState("All locations")
  const [searchValue, setSearchValue] = useState("")
  const [clearToggle, setClearToggle] = useState(false)
  const [initialized, setInitialized] = useState(false)

  const updateJobsAndQuery = () => {
    const query = {}
    if (department !== "All departments") query.dept = department
    if (location !== "All locations") query.loc = location
    if (searchValue !== "") query.search = searchValue

    setFilteredJobs(renderJobs())
  }

  useEffect(() => {
    if (router.isReady) {
      setInitValues()
      setInitialized(true)
    }
  }, [router.isReady])

  useEffect(() => {
    if (router.isReady && initialized) {
      updateJobsAndQuery()
    }
  }, [setSearchValue, department, location, viewAll, clearToggle, initialized])

  const handleClearFilters = () => {
    setDepartment("All departments")
    setLocation("All locations")
    setSearchValue("")
    setClearToggle(!clearToggle)
  }

  const setInitValues = () => {
    const { dept, loc, search } = router.query
    if (dept || loc || search) {
      dept ? setDepartment(dept) : null
      loc ? setLocation(loc) : null
      search ? setSearchValue(search) : null
    }
  }

  const removeRemoteOffice = (offices) => {
    let remoteOffice = []
    const filteredOffices = offices.filter((object) => {
      if (/remote/.test(object.name.toLowerCase())) {
        remoteOffice.push(object)
      }
      return !/remote/.test(object.name.toLowerCase())
    })

    return [filteredOffices, remoteOffice]
  }

  const alphabetizeOffices = (offices) => {
    return offices.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
  }

  const Job = ({ id, title, offices, departments }) => {
    const url = `careers/${id}?gh_jid=${id}`

    const filteredOffices = removeRemoteOffice(offices)
    const sortedOffices = alphabetizeOffices(filteredOffices[0])
    const remoteOffices = filteredOffices[1]

    if (remoteOffices.length > 0) {
      for (let i = 0; i < remoteOffices.length; i++) {
        sortedOffices.push(remoteOffices[i])
      }
    }

    return (
      <Button url={url} key={id} className={job_post}>
        <div className={job_content}>
          <h5>{title}</h5>
          <div className={job_content_column}>
            {departments && departments.length > 0 && <p>{departments[0].name}</p>}
          </div>
          {offices && location !== "All locations" ? (
            <div className={job_content_column}>
              <p>{location}</p>
            </div>
          ) : (
            <div className={job_content_column}>
              <div>
                {sortedOffices.map((office, index) =>
                  index !== sortedOffices.length - 1 ? (
                    <p key={office.id || index}>{office.name},</p>
                  ) : (
                    <p key={office.id || index}>{office.name}</p>
                  )
                )}
              </div>
            </div>
          )}
        </div>
        <div className={on_hover_apply}>
          <p>Apply</p>
          <ReactSVG src={`/icons/arrow-right.svg`}></ReactSVG>
        </div>
      </Button>
    )
  }
  Job.propTypes = {
    id: PropTypes.number,
    title: PropTypes.string,
    offices: PropTypes.array,
    url: PropTypes.string
  }

  const jobs = () => {
    return props.jobs || []
  }

  const renderJobs = () => {
    const filteredJobs = jobs()
      .filter((job) => {
        return (
          location === "All locations" ||
          job.offices
            .map((l) => l.name.trim())
            .flat()
            .includes(location.trim())
        )
      })
      .filter((job) => {
        return (
          department === "All departments" ||
          job.departments
            .map((d) => d.name.trim())
            .flat()
            .includes(department.trim())
        )
      })
      .filter((job) => {
        return job?.title.toLowerCase().includes(searchValue.toLowerCase())
      })

    setJobCount(filteredJobs.length || 0)

    return filteredJobs.slice(0, viewAll ? undefined : INITIAL_VIEWCOUNT)
  }

  const renderDropdown = (label, rows, callback) => {
    const onChange = (value) => {
      callback(value)
    }

    let selected = location
    if (label.includes("department") && department) selected = department

    return (
      <DropdownSelector
        fullWidth={true}
        label=""
        items={[label.includes("department") ? "All departments" : "All locations", ...rows]}
        selected={selected}
        handleSelectedItem={onChange}
        classes={select_input}
      />
    )
  }

  const departments = () => {
    return jobs()
      .map((job) => (job || {}).departments?.map((dept) => dept?.name))
      .flat()
      .filter((value, index, self) => self.indexOf(value) === index)
  }

  const locations = () => {
    return jobs()
      .map((job) => {
        const filteredOffices = removeRemoteOffice(job.offices)
        const sortedOffices = alphabetizeOffices(filteredOffices[0])
        const remoteOffices = filteredOffices[1]

        if (remoteOffices.length > 0) {
          for (let i = 0; i < remoteOffices.length; i++) {
            sortedOffices.push(remoteOffices[i])
          }
        }

        return sortedOffices.map((location) => location?.name)
      })
      .flat()
      .filter((value, index, self) => self.indexOf(value) === index)
  }

  return (
    <div className={current_openings} id="openpositions">
      <h2>Current Openings</h2>

      <div className={jobs_container}>
        <div className={filters_container}>
          <div className={filters_header}>
            <span>Filters</span>
            {(department !== "All departments" || location !== "All locations") && (
              <Link
                className={button_clear_filters}
                href={"/careers"}
                onClick={handleClearFilters}
                replace
                shallow
              >
                Clear filters
                <span className={close_icon}>
                  <ReactSVG src={`/icons/x-mark.svg`} />
                </span>
              </Link>
            )}
          </div>

          {renderDropdown("Filter by department", departments(), setDepartment)}
          {renderDropdown("Filter by location", locations(), setLocation)}
          <p className={total_jobs}>
            {filteredJobs.length !== 1 ? (
              <>{filteredJobs.length} results</>
            ) : (
              <>{filteredJobs.length} result</>
            )}
          </p>
        </div>

        <div className={results_container}>
          {filteredJobs.length > 0 ? (
            <div className={jobs_list}>
              {filteredJobs.map((job, idx) => (
                <Job key={`posting-${idx}`} {...job} />
              ))}
            </div>
          ) : (
            <div>
              <p>
                No results matched the selected filters.
                <button className={button_clear_filters} onClick={handleClearFilters}>
                  Clear filters
                </button>
              </p>
            </div>
          )}
          {!viewAll && jobCount > INITIAL_VIEWCOUNT && (
            <Button className={button_view_all} theme="dark" onClick={() => setViewAll(true)}>
              View all jobs
            </Button>
          )}

          <div className={notice}>
            <h6>NOTICE TO APPLICANTS</h6>
            <p>
              Amperity participates in&nbsp;
              <a
                href="https://assets.ctfassets.net/5vqt5w81x2im/2G1bw5IpJstLXMTWywhASr/0dd27ce7a65f3af60aa4c5323dcd45fc/EVerifyParticipationPoster.pdf"
                target="_blank"
              >
                E-Verify
              </a>
              &nbsp;to confirm work authorization. The E-Verify and&nbsp;
              <a
                href="https://assets.ctfassets.net/5vqt5w81x2im/3Nr1KALSJA47XRGhOuNyxe/108bd0900c042c12ed2a18b03b73e0c1/IER_RightToWorkPoster_Eng_Es.pdf"
                target="_blank"
              >
                workers' rights notices
              </a>
              &nbsp;are available for review.
            </p>
            <p>
              All communication from our hiring teams will only come from an Amperity employee with
              an @amperity.com email address. If you believe you have been a victim of a phishing
              attack, please alert us at &nbsp;
              <a href="mailto:security@amperity.com">security@amperity.com</a>.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

Careers.propTypes = {
  template: PropTypes.string,
  jobs: PropTypes.array
}

export default Careers
