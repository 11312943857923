import { useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"
import Image from "@components/image"
import classNames from "classnames"
import { ReactCompareSlider, ReactCompareSliderImage } from "react-compare-slider"

// ---------------------------------------------------------

import { crossfader, show_img, hide_img } from "./styles.module.scss"

// ---------------------------------------------------------

const ImageComparison = ({
  imageOne,
  imageTwo,
  boundsPadding = 40,
  defaultPosition = 70,
  style
}) => {
  const [showBaseImg, setShowBaseImg] = useState(true)
  const [isIntersecting, setIsIntersecting] = useState(true)
  const ref = useRef(null)

  useEffect(() => {
    if (style === "cross-fade") {
      const handleIntersection = (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setShowBaseImg(false)
          } else {
            setShowBaseImg(true)
          }
          setIsIntersecting(entry.isIntersecting)
        })
      }

      const options = { threshold: 1.0 }

      const observer = new IntersectionObserver(handleIntersection, options)
      observer.observe(ref.current)

      return () => observer.disconnect()
    }
  }, [isIntersecting, style])

  return style === "cross-fade" ? (
    <div className={crossfader} ref={ref}>
      <Image {...imageTwo} alt={imageTwo.alt} className={show_img} />
      <Image
        {...imageOne}
        alt={imageOne.alt}
        className={classNames(showBaseImg ? show_img : hide_img)}
      />
    </div>
  ) : (
    <ReactCompareSlider
      itemOne={<ReactCompareSliderImage {...imageOne} />}
      itemTwo={<ReactCompareSliderImage {...imageTwo} />}
      boundsPadding={boundsPadding}
      position={defaultPosition}
      portrait={style === "vertical-slider"}
    />
  )
}

ImageComparison.propTypes = {
  /**
   * First image for the slider - feel free to change the numbers in Storybook the first one is an image ID, the second is the width and the third is the height.
   */
  imageOne: PropTypes.shape({
    alt: PropTypes.string,
    height: PropTypes.number,
    src: PropTypes.string.isRequired,
    width: PropTypes.number
  }),

  /**
   * Second image for the slider - feel free to change the numbers in Storybook the first one is an image ID, the second is the width and the third is the height.
   */
  imageTwo: PropTypes.shape({
    alt: PropTypes.string,
    height: PropTypes.number,
    src: PropTypes.string.isRequired,
    width: PropTypes.number
  }),

  /**
   * Defines the minimum and maximum bounds for the handles in pixels.
   */
  boundsPadding: PropTypes.number,

  /**
   * Defines the default position of the handle in percent.
   */
  defaultPosition: PropTypes.number,

  /**
   * Image comparison style, horizontal slider (default), veritcal slider, or cross fade. Cross fade Doesn't work in storybook at the moment as I've not figured out how to hook up the IntersectionObserver into Storybook.
   */
  style: PropTypes.oneOf(["horizontal-slider", "vertical-slider", "cross-fade"])
}

export default ImageComparison
