import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

// ---------------------------------------------------------

import { hasDarkBackground } from "@lib/utils"

import AccordionItem from "./accordion-item"

// ---------------------------------------------------------

import { accordion_classes, is_rounded, light_text } from "./styles.module.scss"

// ---------------------------------------------------------

const Accordion = ({
  backgroundColor = "white",
  accordionItemsCollection,
  children,
  isRounded
}) => {
  const darkBackground = backgroundColor && hasDarkBackground(backgroundColor)

  const accordion = classNames(accordion_classes, {
    [`background_${backgroundColor}`]: backgroundColor
  })

  const accordionItems = accordionItemsCollection?.items.map(
    ({ title, content, button, label, labelColor, hasBorder }, idx) => {
      return (
        <AccordionItem
          title={title}
          content={content}
          darkBackground={darkBackground}
          button={button}
          key={idx}
          label={label}
          labelColor={labelColor}
          hasBorder={hasBorder}
        />
      )
    }
  )

  return (
    <div
      className={classNames(
        accordion,
        { [is_rounded]: isRounded },
        darkBackground ? light_text : null
      )}
    >
      {children}
      {accordionItemsCollection && accordionItems}
    </div>
  )
}

Accordion.propTypes = {
  /**
   * Specifies the alert bar background color, choose 1 of 7 main background colors
   */
  backgroundColor: PropTypes.oneOf([
    "white",
    "cement",
    "anvil",
    "ice",
    "lagoon",
    "limoncello",
    "off-black",
    "transparent"
  ]),

  /**
   * Specifies additional class names
   */
  className: PropTypes.string,

  /**
   * Specifies anything between the opening and closing tag.
   */
  children: PropTypes.node,

  /**
   * Specifies whether the accordion container should have rounded corners.
   */
  isRounded: PropTypes.bool,

  /**
   * Specifies the array that's being passed from the cms
   */
  accordionItemsCollection: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
}

export default Accordion
