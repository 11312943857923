import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { ReactSVG } from "react-svg"

import {
  container,
  dropdown_selector,
  dropdown_label,
  full_width,
  open,
  options,
  selector
} from "./styles.module.scss"

const DropdownSelector = ({
  label = "Select",
  items,
  selected,
  handleSelectedItem,
  fullWidth = false,
  classes
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const dropdownRef = useRef()

  // -------------------------------------------------------

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen)
  }

  const closeDropdown = (item) => {
    setDropdownOpen(false)
    if (item) handleSelectedItem(item)
  }
  const handleOutsideClick = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      closeDropdown(false)
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick)
    return () => document.removeEventListener("click", handleOutsideClick)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // -------------------------------------------------------

  return (
    <div className={container}>
      <div className={dropdown_label}>{label}</div>

      <div
        ref={dropdownRef}
        className={classNames(dropdown_selector, classes, { [full_width]: fullWidth })}
      >
        <div
          className={classNames(selector, { [open]: dropdownOpen })}
          tabIndex={0}
          role="button"
          onClick={() => toggleDropdown()}
          onKeyDown={() => toggleDropdown()}
        >
          {selected}
          <ReactSVG src={`/icons/angle-down.svg`} />
        </div>
        <div className={classNames(options, { [open]: dropdownOpen })}>
          {items &&
            items.map((item, index) => {
              let itemClasses = classNames({ [selected]: item === selected })

              return (
                <div
                  className={itemClasses}
                  key={index}
                  tabIndex={0}
                  role="button"
                  onClick={() => closeDropdown(item)}
                  onKeyDown={() => closeDropdown(item)}
                >
                  {item}
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}

DropdownSelector.propTypes = {
  /**
   * Custom classes passed down from parent component.
   */
  classes: PropTypes.string,

  /**
   * array of string values to be selected in the dropdown
   */
  items: PropTypes.array,

  /**
   * dropdown initial label
   */
  label: PropTypes.string,

  /**
   * value of the selected item
   */
  selected: PropTypes.string,

  /**
   * A onClick function passed down to record a selected item
   */
  handleSelectedItem: PropTypes.func,

  /**
   * Should take up the whole width
   */
  fullWidth: PropTypes.bool
}

export default DropdownSelector
